<template>
  <FocusTrap>
    <div class="card" id="mycard" >

      <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
        <h5 v-if="voucher.id == 0" class="card-title">Sale Order Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.vch_no}}  Sale Order Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadBuyers" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="row">

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Buyer</label>
              <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher"  v-model="voucher.buyer_id"  >
                <option v-for="buyer in ledgers" v-bind:value="buyer.id">
                  {{ buyer.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Agent</label>
              <select id="cmbledger" class="form-control" v-if="voucher"  v-model="voucher.agent_id"  >
                <option value="1" selected>-</option>
                <option v-for="agent in agents" v-bind:value="agent.id">
                  {{ agent.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Type</label>
              <select class="form-control select" required="" v-if="voucher"  v-model="voucher.itemtype" @change="enableAddButton" >
                <option value="1" selected>Knitted Rolls</option>
                <option value="2" >Mosquito Nets</option>
              </select>
            </div>
          </div>

          <div class="col-md-2">

          </div>

          <div class="col-md-1">
            <div class="form-group form-group-material">
              <label class=" control-label font-weight-semibold">Ref No</label>
              <input type="text" class="form-control" placeholder="Ref No" minlength="0" maxlength="30" v-if="voucher" v-model="voucher.ref_no">
            </div>
          </div>


          <div class="col-md-2">
            <div class="form-group form-group-material">
              <label class="control-label  font-weight-semibold">Order Date</label>
              <input type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date" placeholder="Invoice Date" >
            </div>
          </div>

        </div>

        <div >
          <div  v-if="voucher.itemtype == 1" class="row">
            <div class="table-responsive">

            <table class="table table-no-bordered">
              <thead style="background-color: lightgrey">
                <tr>
                  <th style="width:50px;">S.No</th>
                  <th >Quality</th>
                  <th style="width:100px; text-align: right;">Rate</th>
                  <th style="width:100px; text-align: right;">Unit</th>
                  <th style="width:75px; text-align: right;">Rolls</th>
                  <th style="width: 30px;">Action</th>
                </tr>
              </thead>
              <tbody v-if="voucher" v-for="(detail,index) in voucher.list" >
                <SaleOrderDetailRow :qualities="qualities"  :myindex="index" :myrow="detail" v-on:oncell_change="oncell_change" v-on:onRemoveRow="removeRow" />
              </tbody>
              <tfoot>
              <tr>
                <td>
                  <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow"  >
                    <i class="icon-plus3"></i>
                  </button>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td  class="font-weight-semibold" style="text-align:right; padding: 0px;"> {{ rowQtyTotal }}</td>
                <td></td>
              </tr>
              </tfoot>
            </table>
          </div>
          </div>

          <div  v-if="voucher.itemtype == 2" class="row">
              <div class="table-responsive">
                <table class="table table-no-bordered">
                  <thead style="background-color: lightgrey">
                  <tr>
                    <th style="width: 100px;" >S.No</th>
                    <th>Quality</th>
                    <th style="width: 120px;">Size</th>
                    <th style="text-align: right;width: 120px;">Rate</th>
                    <th style="text-align: right;width: 120px;">Pcs</th>
                    <th style="width: 30px;">Action</th>
                  </tr>
                  </thead>
                  <tbody v-for="(detail,index) in voucher.list" >
                    <SaleOrderMosquitoRow :myindex="index" :myrow="detail" :qualities = qualities :colors = colors v-on:oncell_change="oncell_change" v-on:onRemoveRow="removeRow" />
                  </tbody>
                  <tfoot>
                  <tr>
                    <td>
                      <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                        <i class="icon-plus3"></i>
                      </button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td  class="font-weight-semibold" style="text-align:right; padding: 0px;"> {{ rowQtyTotal }}</td>
                    <td></td>
                  </tr>
                  </tfoot>
                </table>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group form-group-material">
              <label class="control-label font-weight-semibold">Narration</label>
              <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
            </div>
          </div>


          <div class="col-md-6">

          </div>

          <div class="col-md-3 text-right">
            <div class="form-group row">

            </div>

            <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
            <button id="btnclose" type="button" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>

  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import numeral from 'numeral'
  import { store } from '@/store/store.js'
  import SaleOrderDetailRow from './SaleOrderDetailRow'
  import SaleOrderMosquitoRow from './SaleOrderMosquitoRow'

  export default {
    name: 'SaleOrderForm',
    components: {
      SaleOrderDetailRow,
      SaleOrderMosquitoRow
    },
    store,
    props: {
      myvoucher: {
        type: Object,
        default: () => JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2018,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"agent_id":0,"agent":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"itemtype":1,"remarks":"","list":[]}')
      }
    },
    beforeMount () {
      const self = this;
      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      if( this.myvoucher.id > 0 ) {
        var data = [];
        const request = async () => {
          // const response = await fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/item/`, requestOptions);
          // const json = await response.json();
          // data = json.data;
          // if (_.isArray(data)) {
          //   data.forEach(function (itm) {
          //     self.$data.items.set(itm.id, itm);
          //   });
          // }
          this.voucher = this.myvoucher;

          this.sumRowAmountTotal();
        }
        request();
      }
    },
    data () {
      return {
        readonly: false,
        ledgers:[],
        qualities: [],
        detailItems:[],
        agents : [],
        items : new Map(),
        rowQtyTotal:0.0,
        voucher: JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2018,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"agent_id":0,"agent":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"itemtype":1,"remarks":"","list":[]}'),
      }
    },
    created () {
      const self = this;

      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    mounted () {
      const self = this;
      self.loadBuyers();

      if (self.$data.voucher.doc_date === '0001-01-01'){
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
      }
      self.loadQualities();
      self.loadAgents();
      $('#cmbledger').focus();
    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      closeThis(){
        this.$emit('saleorder_window_closed');
        if(this.voucher.id > 0){
          this.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2018,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"agent_id":0,"agent":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"itemtype":1,"remarks":"","list":[]}');
        }else{
          this.$router.push("/")
        }
      },
      loadQualities () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.qualities = [];
          fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(Array.isArray(resp.data)){
              self.$data.qualities =  resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });
      },
      loadAgents () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.agents = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/agents/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(Array.isArray(resp.data)){
              self.$data.agents =  resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $('#mycard').unblock();
        });
      },
      addRow(){
        try {
          const self = this;
          if(self.voucher.itemtype == 1 ) {
            self.$data.voucher.list.push(JSON.parse('{"idx":0,"quality":{"id":0,"name":""},"size":"","rate":0,"unit_id":0,"qty":0}'));
            setTimeout(function(){
              $("tbody>tr").last().find("td:eq(1) select").focus();
            }, 99);
          }else if(self.voucher.itemtype == 2 ) {
            self.$data.voucher.list.push(JSON.parse('{"idx":0,"quality":{"id":0,"name":""},"size":"","rate":0,"unit_id":0,"qty":0}'));
            setTimeout(function(){
              $("tbody>tr").last().find("td:eq(1) select").focus();
            }, 99);
          }


        }catch (e) {
          alert(e);
        }
      },
      oncell_change(rowIndex, colIndex, itemtype, det) {
        const self = this;
        let detail = self.$data.voucher.list[rowIndex];

        self.$data.rowQtyTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowQtyTotal += parseFloat(detail.qty);
        });
      },
      removeRow(index){
        if (index > -1) {
          this.$data.voucher.list.splice(index, 1);
          this.sumRowAmountTotal();
        }
      },
      sumRowAmountTotal() {
        const self = this;

        self.$data.rowQtyTotal = 0.0;
        self.$data.voucher.list.forEach(function (detail) {
          self.$data.rowQtyTotal += parseFloat(detail.qty);
        });
      },
      clear(){
        $('#btnSave').prop('disabled', false);
        const self = this;
        self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2018,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","buyer_id":0,"buyer":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"agent_id":0,"agent":{"id":0,"type":0,"group_id":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"itemtype":1,"remarks":"","list":[]}');
        self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
        self.$data.voucher.list = [];
        self.$data.rowAmountTotal = 0.0;
        self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
        self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');

        self.$data.rowQtyTotal = 0.0;
        $('#cmbledger').focus();
      },
      loadBuyers() {
        const self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/buyers`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },
      saveVoucher(){
        const self = this;


        try{
          $('#mycard').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          let myvoucher =  JSON.parse(JSON.stringify(self.$data.voucher));
          myvoucher.finyear = self.$store.state.user.finyear;
          myvoucher.status = "Active";
          myvoucher.cmp_id = self.$store.state.user.company.id;
          myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
          myvoucher.ref_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
          myvoucher.rolls = parseInt(self.$data.rowQtyTotal);
          myvoucher.itemtype = parseInt(self.$data.voucher.itemtype);

          myvoucher.rolls = 0;
          myvoucher.list.forEach(function (detail){
            detail.qty = parseFloat(detail.qty);
            detail.rate = parseFloat(detail.rate);
            myvoucher.rolls += parseFloat(detail.qty);
          });

          console.log(JSON.stringify(myvoucher));

          const requestOptions = {
            method:  (self.$data.voucher.id == 0 ? 'POST' : 'PUT' ),
            mode:'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(myvoucher)
          };


          $('#btnSave').prop('disabled', true);
          fetch(`${process.env.VUE_APP_ROOT_API}api/saleorder`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok){


              swal({title: 'Great', type: 'success', text: resp.msg, onClose: () => {
                $('#cmbledger').focus();
              }, timer: 1500  });
              $('#mycard').unblock();
              self.clear();

              $('#cmbledger').focus();

              self.$emit('sale_order_saved', resp.data);
            }else{
              $('#btnSave').prop('disabled', false);
              $('#mycard').unblock();
              swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
            }
          }).catch(function (err) {
            $('#mycard').unblock();
            $('#btnSave').prop('disabled', false);
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
          }).finally(()=>{
            $('#btnSave').prop('disabled', false);
            $('#mycard').unblock();
          });

        }catch (e) {

        }finally {

        }



      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>

<template>
  <tr style="padding: 0px;">
    <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

    <td style="padding: 0px;">
      <select class="form-control" v-model="detail.quality.id" @change="rowValueEqualizer(index, 1)">
        <option v-for="quality in qualities" v-bind:value="quality.id">
          {{ quality.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.rate" @change="rowValueEqualizer(index, 3)" />
    </td>

    <td style="padding: 0px;">
      <select class="form-control" v-model="detail.unit_id" @change="rowValueEqualizer(index, 4)">
        <option value="3">Meter</option>
        <option value="4">Weight</option>
<!--        <option value="2">Pcs</option>-->
      </select>
    </td>

    <td style="padding: 0px;">
      <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0" v-if="detail" v-model="detail.qty" @change="rowValueEqualizer(index, 5)"/>
    </td>

    <td style="padding: 0px;text-align: right;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>
  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import ItemBox from '@/components/core/ItemBox.vue'
  import numeral from 'numeral'
  export default {
    name: 'SaleInvoiceDetailRow',
    store,
    components: {
      ItemBox,
    },
    props: {
      myrow: {
        type: Object,
        default: () => JSON.parse('{"quality":{"id":0,"name":""},"size":"","rate":0,"unit_id":0,"qty":0}')
      },
      myindex: {
        type: Number,
        default: () => 0
      },
      qualities:{
        type: Array,
        default: () => new Array()
      },
    },
    data (){
      return {
        detail: JSON.parse('{"quality":{"id":0,"name":""},"size":"","rate":0,"unit_id":0,"qty":0}'),
        index: 0,
      }
    },
    beforeMount () {
      this.detail = this.myrow;
      this.index = this.myindex;
      this.detail.rate = this.detail.rate.toFixed(2);
    },
    mounted(){
    },
    methods:{
      onItemChanged(item){
        let self = this;
        self.$data.detail.item = item;
        self.rowValueEqualizer(self.$data.detail.idx , 1);

      },
      removeRow(idx){
        this.$emit('onRemoveRow', idx );
      },
      rowValueEqualizer(rowIndex, colIndex) {
        let self = this;
        let detail = self.$data.detail;
        switch (colIndex) {
          case 1:
            break;
          case 2: //GST Rate
            break;
          case 3://Product Rate
            break;
        }
        self.$emit('oncell_change',rowIndex, colIndex,1, detail);
      },

    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>

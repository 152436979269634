<template>
  <tr style="padding: 0px;">
    <td style="text-align: center;padding: 0px;"> {{ detail.idx + 1}} </td>

    <td style="padding: 0px;">
      <select class="form-control" v-model="detail.quality_id" @change="rowValueEqualizer(index, 1)">
        <option v-for="quality in qualities" v-bind:value="quality.id">
          {{ quality.name }}
        </option>
      </select>
    </td>

    <td style="padding: 0px;">
      <select class="form-control" v-model="detail.size" @change="rowValueEqualizer(index, 3)" >
        <option value="3x6.5">3 x 6.5</option>
        <option value="4x6.5">4 x 6.5</option>
        <option value="5x6.5">5 x 6.5</option>
        <option value="6x6.5">6 x 6.5</option>
        <option value="8x6">8 x 6</option>
        <option value="10x7.5">10 x 7.5</option>
      </select>
    </td>

    <td style="padding: 0px;text-align: right; ">
      <input class="form-control text-right" v-model="detail.rate" @change="rowValueEqualizer(index, 4)" />
    </td>

    <td style="padding: 0px;text-align: right; ">
      <input class="form-control text-right" v-model="detail.qty" @change="rowValueEqualizer(index, 5)" />
    </td>

    <td style="padding: 0px;text-align: right;width: 30px;">
      <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
    </td>

  </tr>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'SaleOrderMosquitoRow',
    props: {
      myrow: {
        type: Object,
        default: () => JSON.parse('{"idx":0,"quality":{"id":0,"name":""},"size":"","rate":0,"unit_id":0,"qty":0}')
      },
      myindex: {
        type: Number,
        default: () => 0
      },
      qualities:{
        type: Array,
        default: () => new Array()
      },
      colors:{
        type: Array,
        default: () => new Array()
      },
    },
    beforeMount () {
      this.detail = this.myrow;
      this.index = this.myindex;
      this.detail.idx = this.myindex;
    },
    data () {
      return {
        index: 0,
        detail : JSON.parse('{"idx":0,"quality":{"id":0,"name":""},"size":"","rate":0,"unit_id":0,"qty":0}')
      }
    },
    filters:{
      weightFormat (val){
        return val.toFixed(3);
      }
    },
    methods:{
      removeRow(){
        this.$emit('onRemoveRow', Math.abs(this.$data.fabric.idx) );
      },
      rowValueEqualizer(col) {
        const self = this;
        let colIndex = -1;
        try {

          let detail = self.$data.detail;

          switch (col) {
            case 1: //Quality
              colIndex = 0;
              break;
            case 2: //Color
              break;
            case 3: //Size
              break;
            case 4: //Pcs
              break;
          }
        }catch (e) {

        }
        self.$emit('oncell_change',self.myindex, colIndex, 2, self.$data.detail);
      },
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
